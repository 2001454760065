import {
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React from "react";
import { CSVLink } from "react-csv";
import { BsPlus } from "react-icons/bs";
import { CiImport, CiSettings } from "react-icons/ci";
import { RiDeleteBinLine } from "react-icons/ri";
import Dropdown from "../DropDown";
import "./NewDataTable.css";

const NewDataTable = ({
  children,
  tableHead,
  title,
  data,
  add,
  csvTitle,
  selected,
  handleChange,
  pagination,
  headSelected,
  selecttableHead,
}) => {
  // const numSelected = selected.length;
  const rowCount = data.length;

  return (
    <React.Fragment>
      <Paper className="tableContainer">
        <div className="tableActions">
          <div>
            {/* <Dropdown
            numSelected={numSelected}
            title={"select action"}
            menu={
              <div className="selectdropDown">
                delete selected items
                <RiDeleteBinLine size={24} color="red" onClick={()=>{handleDelete()}}/>
              </div>
            }
          /> */}
          </div>

          <div className="Buttons">
            <CSVLink data={data} filename={csvTitle}>
              <button className="exportButton">
                <CiImport size={24} color="#00AEEF" />
                Export All
              </button>
            </CSVLink>

            <Dropdown
              title={"table settings"}
              icon={<CiSettings size={24} color="#00AEEF" />}
              menu={
                <ul className="selectTableHeaddropDown">
                  {selecttableHead?.map((item, index) => {
                    return (
                      <li key={index}>
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={headSelected[item]}
                              onChange={handleChange}
                              name={item}
                            />
                          }
                          label={item}
                        />
                      </li>
                    );
                  })}
                </ul>
              }
            />
            <button className="addButton" onClick={add}>
              <BsPlus size={24} />
              {title}
            </button>
          </div>
        </div>
        <TableContainer>
          <Table>
            <TableHead className="tableHead">
              <TableRow>
                <TableCell></TableCell>

                {tableHead?.map((headCell) => (
                  <TableCell key={headCell}>{headCell}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </TableContainer>
        {rowCount > 5 && pagination}
      </Paper>
    </React.Fragment>
  );
};
export default NewDataTable;
