//react functional component boilerplate
// import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import DataTable from "../../components/DataTable/DataTable";
import NewDataTable from "../../components/NewDataTable";
import {
  deleteBanner,
  deleteBanners,
  duplicateBanner,
  editBannerStatus,
  getBanners,
} from "../../reducers/Banners";
import { Pagination } from "@material-ui/lab";
import toaster from "../../modules/toaster";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const selecttableHead = [
  "IntID",
  "id",
  "NameAR",
  "NameEN",
  "Target",
  "Order",
  "startDate",
  "status",
  "Actions",
];

const rowsPerPage = 10;

const BannersList = ({
  banners,
  history,
  getBanners,
  editStatus,
  handleDelete,
  deleteBanners,
  initEdit,
  isPending,
}) => {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    IntID: true,
    id: false,
    NameAR: true,
    NameEN: false,
    Target: true,
    Order: true,
    startDate: false,
    status: true,
    Actions: true,
  });
  const [bannersIDs, setBannersIDs] = useState({
    bannersIDs: selected,
  });
  const [lastDeleted, setLastDeleted] = useState();
  const AddNewBanner = () => {
    history.push("/newBanner");
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);
  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setBannersIDs(newSelected);
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
    // setSkip((value - 1) * 20);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    getBanners();
  }, [lastDeleted, banners.length]);
  // ///////////////////
  const handleDeleteAll = () => {
    if (bannersIDs.length === 0) {
      toaster.warning("please select some banners");
    } else {
      deleteBanners(bannersIDs).then(() => {
        setLastDeleted(bannersIDs);
        // loadCases(selectedOrg);
      });
      setSelected([]);
      setLastDeleted(null);
    }
  };
  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <div className="header">
        <h4 className="title">Banners</h4>
      </div>
      <div className="card">
        {banners.length > 0 ? (
          <NewDataTable
            title={"Add New Banner"}
            add={AddNewBanner}
            data={banners}
            tableHead={TableHead}
            selecttableHead={selecttableHead}
            selected={selected}
            handleChange={handleChange}
            headSelected={headSelected}
            setSelected={setSelected}
            handleDelete={handleDeleteAll}
            pagination={
              <Pagination
                // size="small"
                page={page}
                count={Math.ceil(banners.length / 10)}
                // total={projects.length}
                onChange={handlePaginationChange}
              />
            }
          >
            {banners
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <DataTable
                    key={row.id}
                    banner={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    handleDelete={handleDelete}
                    headSelected={headSelected}
                    statusChange={editStatus}
                    getData={getBanners}
                    edit={editStatus}
                    initEdit={initEdit}
                    onClickAddNew={AddNewBanner}
                    setLastDeleted={setLastDeleted}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
            <p style={{ textAlign: "center" }}>No Banners Found</p>
            <button
              className="addButton"
              onClick={() => {
                AddNewBanner;
              }}
            >
              <BsPlus size={24} />
              Add A new Banner
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    selectOrg: state.ORGs.selectOrg,
    orgs: state.ORGs.orgs,
    banners: state.Banners.banners,
    isPending: state.Banners.isPending,
  }),
  (dispatch) => ({
    // addBanner: (banner) => dispatch(addBanner(banner)),
    getBanners: () => dispatch(getBanners("")),
    editStatus: (data) => dispatch(editBannerStatus(data)),
    initEdit: (data) => dispatch(duplicateBanner(data)),
    handleDelete: (data) => dispatch(deleteBanner(data)),
    deleteBanners: (data) => dispatch(deleteBanners(data)),
  })
)(BannersList);
