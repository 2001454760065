import { reducer as formReducer } from "redux-form";
import Activities from "./Activities";
import Auth from "./Auth";
import Banners from "./Banners";
import BrandEmbassadors from "./BrandEmbassadors";
import Cases from "./Cases";
import InMemory from "./InMemory";
import Keywords from "./Keywords";
import Layout from "./Layout";
import MNOs from "./MNOs";
import NGOs from "./NGOs";
import Notifications from "./Notifications";
import ORGs from "./ORGs";
import Projects from "./Projects";
import RamadanNotes from "./RamadanNotes";
import ShortCodes from "./ShortCodes";
import Reports from "./Reports";
import SelectedVideos from "./SelectedVideos";
import Teams from "./Teams";

import adminUser from "./adminUsers";

export default {
  Auth,
  Layout,
  ORGs,
  MNOs,
  Activities,
  Projects,
  Banners,
  InMemory,
  BrandEmbassadors,
  Reports,
  Keywords,
  SelectedVideos,
  RamadanNotes,
  Teams,
  NGOs,
  Notifications,
  ShortCodes,
  adminUser,
  form: formReducer.plugin({
    Case: (state, action) => {
      //      sms_payment[0].keyword_en
      switch (action.type) {
        case "@@redux-form/UNREGISTER_FIELD": {
          if (!state.values) return state;
          const existingFields = Object.keys(state.registeredFields)
            ?.filter((k) => k.includes("["))
            ?.map((k) => ({
              name: k.split("[")[0],
              index: parseInt(k.split("[")[1].split("]")[0]),
            }));

          const valuesThatAreArrays = Object.keys(state.values).filter((k) =>
            Array.isArray(state.values[k])
          );

          const values = state.values;
          valuesThatAreArrays.forEach((key) => {
            const newArr = [];
            const arrIdxKeys = Object.keys(state.values[key]);
            arrIdxKeys.forEach((keyIdx) => {
              const found = existingFields.find((field) => {
                return field.name == key && field.index == keyIdx;
              });
              if (found) newArr.push(state.values[key][keyIdx]);
            });
            values[key] = newArr;
          });
          return {
            ...state,
            values,
          };
        }
        default:
          return state;
      }
    },
    Project: (state, action) => {
      //      sms_payment[0].keyword_en
      switch (action.type) {
        case "@@redux-form/UNREGISTER_FIELD": {
          if (!state.values) return state;
          const existingFields = Object.keys(state.registeredFields)
            ?.filter((k) => k.includes("["))
            ?.map((k) => ({
              name: k.split("[")[0],
              index: parseInt(k.split("[")[1].split("]")[0]),
            }));

          const valuesThatAreArrays = Object.keys(state.values).filter((k) =>
            Array.isArray(state.values[k])
          );

          const values = state.values;
          valuesThatAreArrays.forEach((key) => {
            const newArr = [];
            const arrIdxKeys = Object.keys(state.values[key]);
            arrIdxKeys.forEach((keyIdx) => {
              const found = existingFields.find((field) => {
                return field.name == key && field.index == keyIdx;
              });
              if (found) newArr.push(state.values[key][keyIdx]);
            });
            values[key] = newArr;
          });
          return {
            ...state,
            values,
          };
        }
        default:
          return state;
      }
    },
  }),
  Cases,
};
