import defaultBanner from "assets/images/default-banner-mobile.jpg";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, FormControl, FormGroup } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { setSelectedActivity } from "../../reducers/Activities";
import {
  createBanner,
  duplicateBanner,
  editBannerStatus,
  getBanner,
  uploadBannerImage,
} from "../../reducers/Banners";
import { setSelectedOrg } from "../../reducers/ORGs";
import "./AddBanner.scss";

const AddBanner = ({
  orgs,
  activities,
  selectedOrg,
  activity,
  banner,
  isPending,
  selectOrg,
  selectActivity,
  newBanner,
  editBanner,
  duplicate,
  history,
  uploadImage,
  bannersImage,
  state,
}) => {
  // Activities/Activity[intID]/Organization[intID]
  const [nameAr, setNameAr] = useState("");
  const [selectedTarget, setSelectedTargets] = useState("");
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [selectedActivityName, setSelectedActivityName] = useState("");
  const [target, setTarget] = useState("");
  const [targetUrl, setTargetUrl] = useState("");
  const [intID, setIntID] = useState("");
  const [order, setOrder] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [image, setImage] = useState(null);
  const fileInputRef = useRef();
  const [isEditingMode, setIsEditingMode] = useState(false);

  const [image2, setImage2] = useState();
  const [preview, setPreview] = useState();
  const targets = [
    {
      value: "Open Project Payment Screen",
      label: "Open Project Payment Screen",
    },
    {
      value: "Open Activity Screen",
      label: "Open Activity Screen",
    },
    { value: "Open Url", label: "Open Url" },
  ];

  //upload image function
  //
  console.log(state);
  const BannerId =
    window.location.hash.includes("editBanner") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  useEffect(() => {
    if (image2) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image2);
    } else {
      setPreview(null);
    }
  }, [image2]);
  const handleuploadImage = (e) => {
    e.preventDefault();
    setImage(e.target.files[0]);
    const file = e.target.files[0];
    setImage2(file);
  };
  const handleSubmit = async (e) => {
    console.log("banner");
    e.preventDefault();
    let BannerData = {
      countryid: 1,
      nameAR: nameAr,
      nameEN: nameAr,
      intID: intID,
      order: order,
      target: target,
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      isactive: 0,
      // bannerImage: bannersImage,
    };
    try {
      if (image) {
        const formData = new FormData();
        formData.append("file", image);
        await uploadImage(
          "ikhair/images/sliders",
          "content.quantatel.com",
          formData
        );
      }
      if (window.location.href.includes("editBanner")) {
        console.log(editBanner);
        BannerData.id = BannerId;
        editBanner(BannerData);
        history.push("/banners/list");
      } else {
        newBanner(BannerData);
        history.push("/banners/list");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (BannerId) {
      duplicate(BannerId);
      setIsEditingMode(true);
      setNameAr(banner?.nameAR);
      setIntID(banner?.intID);
      setOrder(banner?.order);
      setTargetUrl(banner?.target);
      setPreview(
        `https://cdn.ikhair.net/ikhair/images/sliders/${banner?.intID}.png`
      );
      // setStartDate(banner?.startDate);
      // setEndDate(banner?.endDate);
    } else {
      setIsEditingMode(false);
    }
  }, [
    BannerId,
    banner?.nameAR,
    banner?.intID,
    banner?.order,
    banner?.target,
    banner?.startDate,
    banner?.endDate,
  ]);
  useEffect(() => {
    if (selectedTarget === "Open Project Payment Screen") {
      _.forEach(orgs, (org) => {
        if (org.id === Number(selectedOrg)) {
          setSelectedOrgName(org.intID);
        }
      });
      _.forEach(activities, (item) => {
        if (item.id === Number(activity)) {
          setSelectedActivityName(item.intID);
        }
      });
      if (selectedOrgName) {
        setTarget(`/activities/${selectedActivityName}/${selectedOrgName}`);
      }
    } else if (selectedTarget === "Open Activity Screen") {
      _.forEach(activities, (item) => {
        if (item.id === Number(activity)) {
          setSelectedActivityName(item.intID);
        }
      });
      setTarget(selectedActivityName);
    } else if (selectedTarget === "Open Url" || selectedTarget === "") {
      setTarget(targetUrl);
    }
  }, [
    nameAr,
    selectedTarget,
    selectedOrg,
    activity,
    selectedActivityName,
    selectedOrgName,
    target,
    targetUrl,
    startDate,
    endDate,
  ]);
  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setStartDate(dateString);
  };
  const onChangeEnd = (date, dateString) => {
    console.log(date, dateString);
    setEndDate(dateString);
  };
  const originalDate = new Date(startDate);
  const formattedDate = `${originalDate.getFullYear()}-${(
    originalDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${originalDate.getDate().toString().padStart(2, "0")}`;

  return (
    <div>
      {isPending && (
        <div className="loader-container">
          <div className="loader">
            <i className="fa fa-spinner" aria-hidden="true" />
          </div>
        </div>
      )}
      <div className="header">
        <h4 className="title">
          {isEditingMode ? `edit Bannner:${banner?.nameAR}` : "Add New Banner"}
        </h4>
      </div>
      <form
        className="card"
        onSubmit={handleSubmit}
        style={{ padding: "12px 24px" }}
      >
        <FormGroup>
          <div className="fieldDiv form-group">
            <label className="control-label">Name Arrrr</label>
            <FormControl
              type="text"
              value={nameAr}
              name="nameAr"
              placeholder="Enter Name"
              onChange={(e) => setNameAr(e.target.value)}
              required
            />
          </div>

          <div className="fieldDiv form-group">
            <label className="control-label">Build Target</label>
            <ButtonGroup
              type="radio"
              name="targets"
              value={targets}
              onChange={(e) => setSelectedTargets(e.target.value)}
              className="radioContainer"
            >
              {targets?.map((target, index) => (
                <div key={index} className="radioGroup">
                  <label className="label-control radioGroup">
                    <input
                      key={target.value}
                      value={target.value}
                      type="radio"
                      checked={selectedTarget === target.value}
                      onChange={(e) =>
                        setSelectedTargets(e.currentTarget.value)
                      }
                    />

                    {target.label}
                  </label>
                </div>
              ))}
            </ButtonGroup>
          </div>

          {selectedTarget === "Open Project Payment Screen" && (
            <div className="fieldDiv form-group">
              <label className="control-label">Organization</label>
              <select
                onChange={(e) => selectOrg(e.target.value)}
                value={selectedOrg}
                className="newProjectSelect selectInput"
              >
                <option value="">Select Organization</option>
                {orgs?.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.nameAR}
                  </option>
                ))}
              </select>
            </div>
          )}
          {(selectedTarget === "Open Activity Screen" ||
            selectedTarget === "Open Project Payment Screen") && (
            <div className="fieldDiv form-group">
              <label className="control-label">Activity</label>
              <select
                onChange={(e) => selectActivity(e.target.value)}
                value={activity}
                className="newProjectSelect selectInput"
              >
                <option value="">Select Activity</option>
                {activities?.map((activity) => (
                  <option key={activity.id} value={activity.id}>
                    {activity.nameAR}
                  </option>
                ))}
              </select>
            </div>
          )}
          {(selectedTarget === "Open Url" || selectedTarget === "") && (
            <div className="fieldDiv form-group">
              <label className="control-label">URL</label>
              <FormControl
                type="text"
                value={targetUrl}
                name="targetUrl"
                placeholder="Enter Name"
                onChange={(e) => setTargetUrl(e.target.value)}
                required
              />
            </div>
          )}
          <div className="fieldDiv form-group">
            <label className="control-label">INIT ID</label>
            <FormControl
              type="text"
              value={intID}
              name="intID"
              placeholder="Enter INT ID"
              onChange={(e) => setIntID(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group">
            <label className="control-label">Order</label>
            <select
              onChange={(e) => setOrder(e.target.value)}
              value={order}
              className="newProjectSelect selectInput"
            >
              {[0, 1, 2, 3].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="fieldDiv form-group">
            <label className="control-label">Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              required
            />
            {startDate != null && startDate != undefined ? (
              <DatePicker
                onChange={onChange}
                defaultValue={moment(formattedDate, "YYYY/MM/DD")}
              />
            ) : (
              ""
            )}
            {console.log(startDate,'startDate')}
          </div>
         {/* <div className="fieldDiv form-group">
            <label className="control-label">End Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              required
            />
            {endDate != null ? (
              <DatePicker
                onChange={onChangeEnd}
                defaultValue={moment(endDate, "YYYY/MM/DD")}
              />
            ) : (
              ""
            )}
             <DatePicker defaultValue={moment(startDate, "YYYY/MM/DD")}
                 format={'YYYY/MM/DD'}   
                  onChange={(date) => setStartDate(date)}/> 
          </div> */}
        </FormGroup>
        <div className="fieldDiv form-group">
          <label className="control-label">
            Upload Banner
            <img
              src={preview || defaultBanner}
              alt="default"
              style={{ width: "124px", height: "124px", display: "block" }}
            />{" "}
          </label>
          <div className="file-input">
            <input
              type="file"
              placeholder="Choose Banner"
              onChange={handleuploadImage}
              className="file"
              id="file"
              style={{ width: "48px", cursor: "pointer" }}
              ref={fileInputRef}
            />
            <button
              htmlFor="file"
              className="uploadBtn"
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current.click();
              }}
            >
              upload
            </button>
            <button
              className="removeBtn"
              type="button"
              onClick={() => {
                setImage(null);
                setPreview(null);
              }}
            >
              Remove
            </button>
          </div>
        </div>
        <div className="btns">
          <div className="submit">
            <Button className="create-edit-form-btn" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
const mapStateToProps = (state) => ({
  orgs: state.ORGs.orgs,
  activities: state.Activities.activities,
  selectedOrg: state.ORGs.selectedOrg,
  activity: state.Activities.activity,
  banner: state.Banners.duplicate,
  isPending: state.Banners.isPending,
  bannersImage: state.Banners.bannerImage,
  state: state,
});
const mapDispatchToProp = (dispatch) => ({
  selectOrg: (org) => dispatch(setSelectedOrg(org)),
  selectActivity: (activity) => dispatch(setSelectedActivity(activity)),
  newBanner: (banner) => dispatch(createBanner(banner)),
  editBanner: (banner) => dispatch(editBannerStatus(banner)),
  duplicate: (banner) => dispatch(duplicateBanner(banner)),
  getBanner: (BannerId) => dispatch(getBanner(BannerId)),
  uploadImage: (folderName, cdnName, file) =>
    dispatch(uploadBannerImage(folderName, cdnName, file)),
});
export default connect(mapStateToProps, mapDispatchToProp)(AddBanner);
