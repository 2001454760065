/* eslint-disable no-undef */

// import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import toaster from "../../modules/toaster";
import {
  generat_Otp,
  getUserData,
  validateUser,
  verifyOTPFunc,
} from "../../reducers/Auth";
import { Button, Form, Input, Spin, Steps, Switch } from "antd";
import { useForm } from "antd/es/form/Form";
import logo from "../../assets/images/logo-light.png";
import "./login.css";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import OtpInput from "react-otp-input";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";

// checkUser

const Auth = ({
  generat_Otp,
  error,
  isPending,
  generateOTP,
  VerifidOTPState,
  verifyOTPFunc,
  getUserData,
}) => {
  const [checkBoxValue, setCheckBoxValue] = useState(true);
  const [current, setCurrent] = useState(0);
  const [generateOTPForm] = useForm();
  const [verifyOTPForm] = useForm();
  const [mobileNumer, setMobileNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [generateOTPResponse, setGenerateOTPResponse] = useState(null);
  const [isLoadingGenerate, setIsLoadingGen] = useState();
  const [isLoadingVerify, setIsLoadingVerify] = useState();
  const [userID, setUserID] = useState("");
  const [otp, setOtp] = useState("");

  // const handleLogin = async (username, pass, e) => {
  //   e.preventDefault();
  //   // if (!captchToken) {
  //   //   toaster.warning("Please verify reCAPTCHA first");
  //   // } else {
  //   try {
  //     const response = await checkUser(username, pass);
  //     localStorage.setItem("objectId", response.objectId);
  //     toaster.success(`Welcome ${response.username}`);
  //   } catch (e) {
  //     console.error(e?.message || e || error);
  //     toaster.error(e?.message || e || error);
  //     setCaptchToken(null);
  //   }
  //   // }
  // };

  // const onCaptchChange = (value) => setCaptchToken(value);

  // const handleClickShowPassword = () => {
  //   setShowPassword((prev) => !prev);
  // };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };
  const onChange = () => {
    setCheckBoxValue(!checkBoxValue);
  };

  const onFinish = async () => {
    setIsLoadingGen(true);
    await generat_Otp(mobileNumer, emailAddress);
    setIsLoadingGen(false);

    setCurrent(1);
  };

  useEffect(() => {
    if (isLoadingGenerate === false) {
      setGenerateOTPResponse({
        access_token: generateOTP.access_token,
        timestamp: generateOTP.timestamp,
        mobile_Number: checkBoxValue ? mobileNumer : undefined,
        email: !checkBoxValue ? emailAddress : undefined,
      });
    }
  }, [isLoadingGenerate]);

  const onVerifyOTPFinish = async () => {
    setIsLoadingVerify(true);
    await verifyOTPFunc(
      otp,
      generateOTPResponse?.timestamp,
      generateOTPResponse?.access_token,
      generateOTPResponse?.mobile_Number,
      generateOTPResponse?.email
    );
    setIsLoadingVerify(false);
  };

  useEffect(() => {
    if (isLoadingVerify === false) {
      if (VerifidOTPState?.access_token && VerifidOTPState?.object_id) {
        const ver = async () => {
          localStorage.setItem("access_token", VerifidOTPState.access_token);
          localStorage.setItem("userObjectId", VerifidOTPState.object_id);
          setUserID(VerifidOTPState.object_id);
          await getUserData(VerifidOTPState.object_id);
        };
        ver();
      }
    }
  }, [isLoadingVerify]);

  return (
    <>
      <section className={`login`}>
        <div className={"signInFormContainer"}>
          {/*<LanguageSwitcher />*/}
          <img src={logo} alt="logo" className="logo" width={120} />
          <div className={"content"}>
            {/* TODO: add loading state */}
            <Spin spinning={false}>
              <p className={"title"}>Sign In by</p>
              <div className="switcher">
                <p>mobile number</p>
                <Switch value={checkBoxValue} onChange={onChange} />
                <p>email address</p>
              </div>
              <Steps
                current={current}
                items={[
                  {
                    title: "Send OTP",
                  },
                  {
                    title: "Verify OTP",
                  },
                ]}
              />
              {current === 0 && (
                <Form
                  form={generateOTPForm}
                  name="generate otp"
                  className={"signInForm"}
                  initialValues={{}}
                  onFinish={onFinish}
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <Form.Item
                    label={checkBoxValue ? "Mobile Number" : "Email Address"}
                    className={"fieldItemContainer"}
                    name={checkBoxValue ? "mobile_number" : "email_address"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: checkBoxValue
                          ? "Please input the mobile number!"
                          : "Please input the Email address!",
                      },
                      checkBoxValue
                        ? () => ({
                            validator(_, value) {
                              if (
                                value.startsWith("9715") &&
                                value.length === 12
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Please enter a valid UAE mobile number"
                                )
                              );
                            },
                          })
                        : () => ({
                            validator(_, value) {
                              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              if (emailRegex.test(value)) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Please enter a valid email address")
                              );
                            },
                          }),
                    ]}
                  >
                    {checkBoxValue ? (
                      <Input
                        prefix={<FaMobileAlt />}
                        placeholder="Enter UAE Mobile e.g. 9715xxxxxxxx"
                        style={{
                          padding: "12px",
                          borderRadius: "8px",
                          fontSize: "17px",
                        }}
                        onChange={(e) => {
                          setMobileNumber(e.target.value);
                        }}
                      />
                    ) : (
                      <Input
                        prefix={<MdOutlineMail />}
                        placeholder="Enter the Email address"
                        style={{
                          padding: "12px",
                          borderRadius: "8px",
                          fontSize: "17px",
                        }}
                        onChange={(e) => {
                          setEmailAddress(e.target.value);
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item className={"signInButtonContainer"}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className={"signInButton"}
                      loading={isLoadingGenerate}
                    >
                      Send OTP
                    </Button>
                  </Form.Item>
                </Form>
              )}
              {current === 1 && (
                <Form
                  form={verifyOTPForm}
                  name="verify otp"
                  className={"signInForm"}
                  initialValues={{}}
                  // onFinish={onVerifyOTPFinish}
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <Form.Item
                    label="OTP"
                    className={"fieldItemContainer"}
                    name="otp"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please input received OTP!",
                      },
                      () => ({
                        validator(_, value) {
                          if (value.length > 4) {
                            return Promise.reject("Please enter a valid OTP");
                          }

                          // validate OTP to be numbers only
                          if (!value.match(/^[0-9]+$/)) {
                            return Promise.reject("Please enter a valid OTP");
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    {/* <Input.OTP length={4} type="number" autoFocus /> */}
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      className="otp-input"
                      // renderSeparator={<span>-</span>}

                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{
                            width: "77px",
                            height: "46px",
                            padding: "11px 4px",
                            borderRadius: "8px",
                            border: "2px solid #eee",
                            textAlign: "center",
                          }}
                        />
                      )}
                    />
                  </Form.Item>

                  <Form.Item className={"signInButtonContainer"}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className={"signInButton"}
                      loading={isLoadingVerify}
                      onClick={onVerifyOTPFinish}
                    >
                      Validate OTP
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Spin>
          </div>
        </div>
      </section>
      <Toaster />
    </>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Auth.isPending,
  error: state.Auth.error,
  user: state.Auth.user,
  isLogged: state.Auth.isLoggedIn,
  generateOTP: state.Auth.generateOTP,
  VerifidOTPState: state.Auth.verify_OTp,
  userDataState: state.Auth.user_Data,
  UserVerify: state.Auth.user,
});

const actions = (dispatch) => ({
  generat_Otp: (mobileNumer, email) =>
    dispatch(generat_Otp(mobileNumer, email)),
  verifyOTPFunc: (otp, timestamp, access_token, mobileNumber, email) =>
    dispatch(verifyOTPFunc(otp, timestamp, access_token, mobileNumber, email)),
  getUserData: (userId) => dispatch(getUserData(userId)),
  validateUser: (auth, objectID) => dispatch(validateUser(auth, objectID)),
});

export default connect(mapStateToProps, actions)(Auth);

Auth.propTypes = {
  generat_Otp: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPending: PropTypes.bool.isRequired,
};
Auth.propTypes = {
  getUserData: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPending: PropTypes.bool.isRequired,
};
Auth.propTypes = {
  validateUser: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPending: PropTypes.bool.isRequired,
};

Auth.propTypes = {
  generateOTP: PropTypes.oneOfType([
    PropTypes.shape({
      access_token: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
    }),
    PropTypes.array,
  ]),
};

Auth.propTypes = {
  verifyOTPFunc: PropTypes.func.isRequired,
  verifyOTPFuncArgs: PropTypes.shape({
    otp: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    access_token: PropTypes.string.isRequired,
    mobileNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

Auth.propTypes = {
  VerifidOTPState: PropTypes.oneOfType([
    PropTypes.shape({
      access_token: PropTypes.string.isRequired,
      object_id: PropTypes.string.isRequired,
      new_user: PropTypes.bool.isRequired,
    }),
    PropTypes.array,
  ]),
};

Auth.propTypes = {
  userDataState: PropTypes.oneOfType([
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      error: PropTypes.string,
      lastOrderDate: PropTypes.shape({
        __type: PropTypes.string.isRequired,
        iso: PropTypes.string.isRequired,
      }),
      lastOrderValue: PropTypes.number,
      ltv: PropTypes.number,
      mobile_number: PropTypes.string.isRequired,
      objectId: PropTypes.string.isRequired,
      ordersNum: PropTypes.number,
      peek_hour: PropTypes.number,
      push_enabled: PropTypes.bool,
      role: PropTypes.string.isRequired,
      smartlink_id: PropTypes.string,
      top_hour: PropTypes.number,
      updatedAt: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      webpush_enabled: PropTypes.bool,
    }),
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};
