import { Button, Form, Input, Spin, Steps, Switch } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import OTPInput from "react-otp-input";
import "./admin.css";
import { connect } from "react-redux";
import { generat_Otp, verifyOTPFunc } from "../../reducers/Auth";
import { Get_Specific_Admin, UpdateToAdmin } from "../../reducers/adminUsers";
import { Toaster } from "react-hot-toast";
const AddAdminnn = ({
  generat_Otp,
  generateOTP,
  verifyOTPFunc,
  VerifidOTPState,
  UpdateToAdmin,
  history,
}) => {
  const [current, setCurrent] = useState(0);
  const [emailAddress, setEmailAddress] = useState("");
  const [mobileNumer, setMobileNumber] = useState("");
  const [generateOTPForm] = useForm();
  const [checkBoxValue, setCheckBoxValue] = useState(true);
  const [isLoadingGen, setIsLoadingGen] = useState(false);
  const [generateOTPResponse, setGenerateOTPResponse] = useState(null);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [isverified, setIsverified] = useState(true);
  const [otp, setOtp] = useState("");

  const onChange = () => {
    setCheckBoxValue(!checkBoxValue);
  };

  const onFinish = async () => {
    setIsLoadingGen(true);
    await generat_Otp(mobileNumer, emailAddress);
    setIsLoadingGen(false);
    setCurrent(1);
  };

  useEffect(() => {
    if (isLoadingGen === false) {
      setGenerateOTPResponse({
        access_token: generateOTP.access_token,
        timestamp: generateOTP.timestamp,
        mobile_Number: checkBoxValue ? mobileNumer : undefined,
        email: !checkBoxValue ? emailAddress : undefined,
      });
    }
  }, [isLoadingGen]);
  const onVerifyOTPFinish = async () => {
    setIsLoadingVerify(true);
    setIsverified(true);
    await verifyOTPFunc(
      otp,
      generateOTPResponse?.timestamp,
      generateOTPResponse?.access_token,
      generateOTPResponse?.mobile_Number,
      generateOTPResponse?.email
    );
    setIsverified(false);
    setIsLoadingVerify(false);
    history.push("/adminUsers");
  };

  useEffect(() => {
    if (isverified === false) {
      UpdateToAdmin(VerifidOTPState.object_id, { role: "admin" });
    }
  }, [isverified]);

  return (
    <div className="container">
      <div className="header">
        <h1>
          {checkBoxValue
            ? "Add new Admin User by Mobile Number"
            : "Add new Admin User by Email Address"}
        </h1>
        <Switch defaultChecked onChange={onChange} />
      </div>
      <Spin spinning={false}>
        <Steps
          current={current}
          items={[
            {
              title: "Send OTP",
            },
            {
              title: "Verify OTP",
            },
          ]}
        />
        {current === 0 && (
          <Form
            form={generateOTPForm}
            name="generate otp"
            initialValues={{}}
            onFinish={onFinish}
            style={{
              marginTop: "30px",
            }}
          >
            <Form.Item
              label={checkBoxValue ? "Mobile Number" : "Email Address"}
              name={checkBoxValue ? "mobile_number" : "email_address"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: checkBoxValue
                    ? "Please input the mobile number!"
                    : "Please input the Email address!",
                },
                checkBoxValue
                  ? () => ({
                      validator(_, value) {
                        if (value.startsWith("9715") && value.length === 12) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please enter a valid UAE mobile number")
                        );
                      },
                    })
                  : () => ({
                      validator(_, value) {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (emailRegex.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please enter a valid email address")
                        );
                      },
                    }),
              ]}
            >
              {checkBoxValue ? (
                <Input
                  prefix={<FaMobileAlt />}
                  placeholder="Enter UAE Mobile e.g. 9715xxxxxxxx"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    fontSize: "17px",
                  }}
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                  defaultValue={mobileNumer}
                />
              ) : (
                <Input
                  prefix={<MdOutlineMail />}
                  placeholder="Enter the Email address"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    fontSize: "17px",
                  }}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                  }}
                  defaultValue={emailAddress}
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoadingGen}
              >
                Send OTP
              </Button>
            </Form.Item>
          </Form>
        )}
        {current === 1 && (
          <Form
            // form={verifyOTPForm}
            name="verify otp"
            initialValues={{}}
            // onFinish={onVerifyOTPFinish}
            style={{
              marginTop: "30px",
            }}
          >
            <Form.Item
              label="OTP"
              name="otp"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please input received OTP!",
                },
                () => ({
                  validator(_, value) {
                    if (value.length > 4) {
                      return Promise.reject("Please enter a valid OTP");
                    }

                    // validate OTP to be numbers only
                    if (!value.match(/^[0-9]+$/)) {
                      return Promise.reject("Please enter a valid OTP");
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {/* <Input.OTP length={4} type="number" autoFocus /> */}
              <div className="otp_input">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  // renderSeparator={<span>-</span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        width: "82px",
                        height: "46px",
                        padding: "11px 4px",
                        borderRadius: "8px",
                        border: "2px solid #eee",
                        textAlign: "center",
                      }}
                    />
                  )}
                />
              </div>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                //   className={classes.signInButton}
                loading={isLoadingVerify}
                onClick={onVerifyOTPFinish}
              >
                Validate OTP
              </Button>
            </Form.Item>
          </Form>
        )}
      </Spin>
      <Toaster />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Auth.isPending,
  error: state.Auth.error,
  user: state.Auth.user,
  isLogged: state.Auth.isLoggedIn,
  generateOTP: state.Auth.generateOTP,
  VerifidOTPState: state.Auth.verify_OTp,
  updateAdmin: state.adminUser.updateAdmin,
  oneAdmin: state.adminUser.oneAdmin,

  // userDataState: state.Auth.user_Data,
  // UserVerify: state.Auth.user,
});

const actions = (dispatch) => ({
  generat_Otp: (mobileNumer, email) =>
    dispatch(generat_Otp(mobileNumer, email)),
  verifyOTPFunc: (otp, timestamp, access_token, mobileNumber, email) =>
    dispatch(verifyOTPFunc(otp, timestamp, access_token, mobileNumber, email)),
  UpdateToAdmin: (id, role) => dispatch(UpdateToAdmin(id, role)),
  Get_Specific_Admin: (id) => dispatch(Get_Specific_Admin(id)),
  //   getUserData: (userId) => dispatch(getUserData(userId)),
  //   validateUser: (auth, objectID) => dispatch(validateUser(auth, objectID)),
});

export default connect(mapStateToProps, actions)(AddAdminnn);
