//react functional component boilerplate
// import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import NewDataTable from "../../components/NewDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Get_All_Users, UpdateToAdmin } from "../../reducers/adminUsers";
import AdminDataTable from "../../components/AdminUsersDataTable/AdminDataTable";
import { Toaster } from "react-hot-toast";

const selecttableHead = ["id", "Name", "role", "email", "Actions"];

const rowsPerPage = 10;

const AdminUser = ({
  history,
  editStatus,
  handleDelete,
  isPending,
  GetAllUsers,
  AllUsers,
  updateAdmin,
}) => {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    id: true,
    Name: true,
    role: true,
    email: true,
    mobile_number: true,
    Actions: true,
  });
  const [lastDeleted, setLastDeleted] = useState();
  const AddNewAdmin = () => {
    history.push("/addAdmin");
  };

  console.log(updateAdmin);

  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);
  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handlePaginationChange = (event, value) => {
  //   setPage(value);
  //   // setSkip((value - 1) * 20);
  // };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    GetAllUsers();
  }, [lastDeleted, AllUsers?.users?.length]);
  // ///////////////////

  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <div className="header">
        <h4 className="title">Add new admins</h4>
      </div>
      <div className="card">
        {AllUsers?.users?.length > 0 ? (
          <NewDataTable
            title={"Add New Admin"}
            add={AddNewAdmin}
            data={AllUsers?.users}
            tableHead={TableHead}
            selecttableHead={selecttableHead}
            selected={selected}
            handleChange={handleChange}
            headSelected={headSelected}
            setSelected={setSelected}
            // pagination={
            //   <Pagination
            //     // size="small"
            //     page={page}
            //     count={Math.ceil(banners.length / 10)}
            //     // total={projects.length}
            //     onChange={handlePaginationChange}
            //   />
            // }
          >
            {AllUsers?.users
              .slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              ?.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <AdminDataTable
                    key={index}
                    Admins={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    handleDelete={handleDelete}
                    headSelected={headSelected}
                    statusChange={editStatus}
                    getData={GetAllUsers}
                    edit={editStatus}
                    onClickAddNew={AddNewAdmin}
                    setLastDeleted={setLastDeleted}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
            <p style={{ textAlign: "center" }}>No Admins Found</p>
            <button
              className="addButton"
              onClick={() => {
                AddNewAdmin;
              }}
            >
              <BsPlus size={24} />
              Add A new Banner
            </button>
          </div>
        )}
      </div>
      <Toaster></Toaster>
    </div>
  );
};

export default connect(
  (state) => ({
    isPending: state.adminUser.isPending,
    AllUsers: state.adminUser.Users,
    updateAdmin: state.adminUser.updateAdmin,
  }),
  (dispatch) => ({
    handleDelete: (id, role) => dispatch(UpdateToAdmin(id, role)),
    GetAllUsers: () => dispatch(Get_All_Users()),
  })
)(AdminUser);
