import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { connect } from "react-redux";
import { Get_Specific_Admin, UpdateToAdmin } from "../../reducers/adminUsers";
import { useForm } from "antd/es/form/Form";
import toast, { Toaster } from "react-hot-toast";

const EditAdmin = (params) => {
  const [isLoadingGen, setIsLoadingGen] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = params.match.params;
  const { oneAdmin } = params;
  const { UpdateToAdmin } = params;
  const { updateAdmin } = params;
  const { history } = params;

  const [form] = useForm();

  useEffect(() => {
    const get = async () => {
      setIsLoading(true);
      await params.Get_Specific_Admin(id);
      setIsLoading(false);
    };
    get();
  }, []);

  // to get data from Api
  useEffect(() => {
    if (isLoading === false) {
      form.setFieldsValue({
        mobile_number: oneAdmin.mobile_number,
        email_address: oneAdmin.email,
      });
    }
    if (isLoadingGen === false && updateAdmin.status === 200) {
      toast.success("user updated successfully");
      setTimeout(() => {
        history.push("/adminUsers");
      }, 1500);
    }
  }, [isLoading, isLoadingGen]);

  const onfinish = async (values) => {
    setIsLoadingGen(true);
    await UpdateToAdmin(id, {
      email: values.email_address,
      mobile_number: values.mobile_number,
    });
    setIsLoadingGen(false);
  };
  return (
    <div className="container">
      <div className="header">
        <h1>Edit Account</h1>
      </div>
      <Form onFinish={onfinish} form={form}>
        <Form.Item
          label={"Mobile Number"}
          name={"mobile_number"}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input the mobile number!",
            },
            () => ({
              validator(_, value) {
                if (value.startsWith("9715") && value.length === 12) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid UAE mobile number")
                );
              },
            }),
          ]}
        >
          <Input
            prefix={<FaMobileAlt />}
            placeholder="Enter UAE Mobile e.g. 9715xxxxxxxx"
            style={{
              padding: "12px",
              borderRadius: "8px",
              fontSize: "17px",
            }}
          />
        </Form.Item>

        <Form.Item
          label={"Email Address"}
          name={"email_address"}
          labelCol={{ span: 24 }}
          rules={[
            () => ({
              validator(_, value) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (emailRegex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid email address")
                );
              },
            }),
          ]}
        >
          <Input
            prefix={<MdOutlineMail />}
            placeholder="Enter the Email address"
            style={{
              padding: "12px",
              borderRadius: "8px",
              fontSize: "17px",
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isLoadingGen}
          >
            Update Admin
          </Button>
        </Form.Item>
      </Form>
      <Toaster></Toaster>
    </div>
  );
};

const mapStateToProps = (state) => ({
  updateAdmin: state.adminUser.updateAdmin,
  oneAdmin: state.adminUser.oneAdmin,
});

const actions = (dispatch) => ({
  UpdateToAdmin: (id, role) => dispatch(UpdateToAdmin(id, role)),
  Get_Specific_Admin: (id) => dispatch(Get_Specific_Admin(id)),
  //   getUserData: (userId) => dispatch(getUserData(userId)),
  //   validateUser: (auth, objectID) => dispatch(validateUser(auth, objectID)),
});

export default connect(mapStateToProps, actions)(EditAdmin);
