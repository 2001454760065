import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import Switch from "components/Switch";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import toaster from "../../modules/toaster";
import "./DataTable.css";
import { Tag } from "antd";

const AdminDataTable = ({
  initEdit,
  dataItems,
  statusChange,
  getData,
  title,
  onClickAddNew,
  handleDelete,
  handleClick,
  Admins,
  isItemSelected,
  key,
  labelId,
  headSelected,
  setLastDeleted,
}) => {
  const handleEdit = (item) => {
    // initEdit(item.id);
    console.log(item);
    onClickAddNew(`/editAdmin/${item.id}`);
  };
  // console.log(initEdit(Admins.id),'initEdit');
  useEffect(() => {}, [dataItems]);
  return (
    <React.Fragment>
      <TableRow
        hover
        // role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={key}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, Admins._id)}
            role="checkbox"
          />
        </TableCell>
        {headSelected.id && <TableCell>{Admins._id}</TableCell>}
        {headSelected.Name && Admins.name !== null ? (
          <TableCell>{Admins.name}</TableCell>
        ) : (
          <TableCell>
            <Tag color="red">not exist</Tag>
          </TableCell>
        )}
        {headSelected.role && <TableCell>{Admins.role}</TableCell>}
        {headSelected.email && Admins.email !== null ? (
          <TableCell>{Admins.email}</TableCell>
        ) : (
          <TableCell>
            <Tag color="red">not exist</Tag>
          </TableCell>
        )}
        {headSelected.mobile_number && Admins.mobile_number !== null ? (
          <TableCell>{Admins.mobile_number}</TableCell>
        ) : (
          <TableCell>
            <Tag color="red">not exist</Tag>
          </TableCell>
        )}

        {headSelected.Actions && (
          <TableCell>
            <div className="TableIcons">
              <Link to={`/editAdmin/${Admins._id}`}>
                <FaRegEdit
                  cursor="pointer"
                  size={24}
                  color="#00AEEF"
                  onClick={() => handleEdit(Admins)}
                />
              </Link>
              {handleDelete && (
                <RiDeleteBinLine
                  cursor="pointer"
                  onClick={() => {
                    handleDelete(Admins._id, { role: "user" });

                    setLastDeleted(Admins._id);
                  }}
                  color="#ED1650"
                  size={24}
                />
              )}
            </div>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default AdminDataTable;

AdminDataTable.propTypes = {
  initEdit: PropTypes.func,
  dataItems: PropTypes.array,
  statusChange: PropTypes.func,
  getData: PropTypes.func,
  title: PropTypes.string,
  onClickAddNew: PropTypes.func,
  handleDelete: PropTypes.func,
  handleClick: PropTypes.func,
  Admins: PropTypes.object,
  isItemSelected: PropTypes.bool,
  key: PropTypes.number,
  labelId: PropTypes.number,
  headSelected: PropTypes.object,
};
