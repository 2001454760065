import toast from "react-hot-toast";
import IkhairServerInstance from "../axios/ikhairInstance";

const GET_USERS = "GET_USERS";
const USERS_PENDING = "USERS_PENDING";
const UPDATE_ADMIN = "UPDATE_ADMIN";
const SPECIFIC_ADMIN = "SPECIFIC_ADMIN";

const defaultState = {
  isPending: null,
  error: null,
  Users: [],
  oneAdmin: [],
  updateAdmin: [],
};

export const Get_All_Users = () => async (dispatch) => {
  dispatch({ type: USERS_PENDING });

  const token = localStorage.getItem("access_token");
  const userObjectId = localStorage.getItem("userObjectId");
  try {
    const response = await IkhairServerInstance.get(
      "/users?role=admin",

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Object-Id": `${userObjectId}`,
        },
      }
    );

    dispatch({
      type: GET_USERS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// update role to admin
export const UpdateToAdmin = (id, role) => async (dispatch) => {
  dispatch({ type: USERS_PENDING });

  try {
    const response = await IkhairServerInstance.put(`users/${id}`, role, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    if (response.status === 200) {
      if (role.role === "admin") {
        toast.success("Admin Added successfully");
      }

      if (role.role === "user") {
        toast.success("user deleted successfully");
      }
    }

    dispatch({
      type: UPDATE_ADMIN,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: UPDATE_ADMIN,
      payload: error,
    });
    if (error.response.status === 400) {
      toast.error(error.response.data.message);
    }
  }
};

export const Get_Specific_Admin = (id) => async (dispatch) => {
  dispatch({ type: USERS_PENDING });

  try {
    const response = await IkhairServerInstance.get(`/users/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SPECIFIC_ADMIN,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USERS_PENDING: {
      return { ...state, isPending: true };
    }
    case GET_USERS: {
      return {
        ...state,
        Users: action.payload,
        isPending: false,
        error: action.error,
      };
    }
    case UPDATE_ADMIN: {
      return {
        ...state,
        updateAdmin: action.payload,
        error: action.error,
        isPending: false,
      };
    }
    case SPECIFIC_ADMIN: {
      return {
        ...state,
        oneAdmin: action.payload,
        error: action.error,
        isPending: false,
      };
    }
    default:
      return state;
  }
}
